.carousel-control-prev,
.carousel-control-next {
  display: none !important;
}

.icons-list div {
  line-height: 40px;
  white-space: nowrap;
  cursor: default;
  position: relative;
  z-index: 1;
  padding: 5px;
  border-right: 1px solid #252a33;
}

.icons-list div i {
  display: inline-block;
  width: 40px;
  margin: 0;
  text-align: center;
  vertical-align: middle;
  -webkit-transition: font-size 0.2s;
  -moz-transition: font-size 0.2s;
  transition: font-size 0.2s;
}

.icons-list div:hover i {
  font-size: 26px;
}

.form_wizard_tab {
  background-color: #6f7b7d;
  border-radius: 0.1875rem;
  color: #fff;
  margin-right: 2px;
}

.form_wizard_tab:hover {
  background-color: #6f7b7d;
  border-radius: 0.1875rem;
  color: #fff;
  margin-right: 2px;
}

.form_wizard_tab.active {
  background-color: #17c2d7 !important;
  border-color: transparent !important;
  color: #fff !important;
}

.form_wizard_width {
  width: calc(25% - 2px);
}

.left_dropdown_btn {
  background-color: transparent !important;
  border: 0 !important;
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.left_dropdown_btn:hover {
  background-color: transparent;
  border: 0 !important;
  box-shadow: unset !important;
  outline: 0 !important;
}

.left_dropdown_btn:active {
  background-color: transparent !important;
  border: 0 !important;
  box-shadow: unset !important;
  outline: 0 !important;
}

.left_dropdown_btn:focus {
  background-color: transparent !important;
  border: 0 !important;
  box-shadow: unset !important;
  outline: 0 !important;
}

.user-account .dropdown .dropdown-menu {
  top: 0 !important;
}

.sidebar-scroll {
  overflow-y: auto;
  overflow-x: scroll;
  -ms-overflow-style: none;
  height: 100vh;
}

.sidebar-scroll::-webkit-scrollbar {
  width: 0;
  background-color: #282b2f;
}

.sidebar-scroll::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: #282b2f;
}

.black-editor {
  background-color: #282b2f !important;
}

.text-editor-black .public-DraftEditor-content {
  height: 150px;
  border: 1px solid #f1f1f1;
  padding: 5px;
}

.custom_tab.active {
  background-color: #2f3338 !important;
  border-color: #2f3338 !important;
  color: #a5a8ad !important;
}

.custom_tab.nav-link {
  color: #ffffff;
}

.mini_sidebar .mini_sidebar_on .user-account {
  display: none !important;
}

/* ssd */
.sidebar-scroll .sidebar-nav {
  padding-bottom: 100px;
}

.custom-icon {
  color: #a5a8ad;
  font-size: 20px;
}

.custom-icon:hover {
  color: #007bff;
}

.input-field-side-text {
  margin: auto 20px auto 0;
}

.modal-input-group {
  justify-content: space-between;
}

.modal-input-group .form-control {
  max-width: 450px !important;
  /*max-width: 360px !important;*/
}

.modal-container {
  background-color: rgba(0, 0, 0, 0.384);
}

.text-center {
  text-align: center !important;
}

.dd-handle.align-items-center.active {
  border-color: rgb(23, 194, 215) !important;
  background-color: #2e373c !important;
}

.light_version .dd-handle.align-items-center.active {
  border-color: rgb(23, 194, 215) !important;
  background-color: #64bfa440 !important;
}

.taskboard .action .action-icon {
  padding: 0 5px;
  color: #007bff;
  display: inline;
}

.taskboard .action .action-icon:hover {
  color: #437ac7 !important;
}

.taskboard .dd-handle {
  cursor: pointer;
}

.taskboard .custom-icon {
  cursor: pointer;
}

.assign-user-table tr td:last-child {
  /*margin: 0;*/
  width: 0;
  /*white-space:nowrap;*/
}

.limit-name {
  content: "...";
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
  /*width: 160px;*/
}

.limit-new-username {
  content: "";
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
  max-width: 100px;
  /*width: 110px;*/
}

.user-image {
  width: 84px;
  height: 84px;
}

.personal-image {
  text-align: center;
}

.personal-image input[type="file"] {
  display: none;
}

.personal-figure {
  position: relative;
  width: 80px;
  height: 80px;
}

.personal-avatar {
  cursor: pointer;
  width: 80px;
  height: 80px;
  box-sizing: border-box;
  border-radius: 100%;
  border: 2px solid transparent;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
  transition: all ease-in-out 0.3s;
  object-fit: cover;
}

.personal-avatar:hover {
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
}

.personal-figcaption {
  cursor: pointer;
  position: absolute;
  top: 0px;
  width: inherit;
  height: inherit;
  border-radius: 100%;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0);
  transition: all ease-in-out 0.3s;
}

.personal-figcaption:hover {
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.5);
}

.personal-figcaption > img {
  margin-top: 28px;
  width: 20px;
  height: 20px;
}

.profile-figcaption {
  cursor: pointer;
  position: absolute;
  top: 23px;
  width: inherit;
  height: inherit;
  border-radius: 100%;
  opacity: 0;
  left: 145px;
  background-color: rgba(0, 0, 0, 0);
  transition: all ease-in-out 0.3s;
}

.profile-figcaption:hover {
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.5);
}

.profile-figcaption > img {
  margin-top: 35px;
  width: 20px;
  height: 20px;
}

.img-input-label {
  margin: 0;
}

/* .country-select:hover {
    background-color: #007bff;
    cursor: pointer;
    color: #fff;
}

.country-select:hover span {
    background-color: #ffffff;
    color: #007bff;
} */

.breadcrumb2 {
  display: flex;
  flex-wrap: wrap;
  /*padding: .75rem 1rem;*/
  /*margin-bottom: 1rem;*/
  list-style: none;
  /*background-color: #e9ecef;*/
  border-radius: 0.25rem;
}

.header-counter-center1 {
  height: 100%;
  align-content: center;
}

.header-counter-center1 small {
  margin: 0;
  padding-left: 0.5rem;
  display: inline-block;
}

.landing-page-main-cards .card-right h6 {
  margin-bottom: 0 !important;
}

.landing-page-main-cards .card-right span {
  margin-top: 0.4rem;
  display: block;
}

.branch-details-card .branch-details-img {
  height: 80px;
  width: 80px;
  border-radius: 100px !important;
  object-fit: cover;
}

.branch-details-card .media {
  align-items: center;
}

.branch-details-card-content td {
  padding: 0.75rem 0;
}

.header-counter-center {
  height: 100%;
  align-content: center;
}

.header-counter-center h5 {
  margin: 0;
}

.stop-overflow-x {
  overflow-x: hidden;
}

.action-col-align th:last-child {
  width: 0;
}

.btn-circle {
  border-radius: 50%;
}

.modal-select-field {
  font-size: inherit !important;
  text-transform: capitalize;
}

.btn-unselected {
  color: #77797c;
  border: #777c7b87 1px solid;
}

.btn-unselected:hover {
  color: #77797c;
  border: #777c7b87 1px solid;
}

.btn-selected {
  color: #28a745;
  border-color: #28a745;
}

.btn-selected:hover {
  color: #28a745;
  border-color: #28a745;
}

.counter-add-form .input-field-side-text {
  width: 150px;
}

.btn-selected,
.btn-unselected {
  /* width: fit-content; */
  display: inline-block;
}

.btn-selected:focus,
.btn-unselected:focus {
  outline: 0;
  box-shadow: none;
}

.light_version .row-text-diseble {
  color: #d0d0d0 !important;
}

.row-text-diseble {
  color: #d0d0d012 !important;
}

.rc-time-picker-panel-select::-webkit-scrollbar {
  width: 0px;
}

.category-add-form .input-field-side-text {
  width: 170px;
}

.rc-time-picker-input {
  flex: 1 1 !important;
  background-color: transparent !important;
  border: 0px solid #393d42 !important;
  font-size: 14px !important;
  font-family: inherit !important;
  color: #495057 !important;
  /* padding: 0.375rem 1.75rem 0.375rem 0.75rem!important; */
  padding: 0 !important;
}

.rc-time-picker-input:focus {
  border-color: none !important;
  outline: 0 !important;
  box-shadow: none !important;
}

.rc-time-picker-clear {
  right: 23px !important;
  top: 8px !important;
}

.modal {
  overflow-y: auto;
}

.rangeslider-horizontal {
  height: 5px !important;
}

.rangeslider-horizontal .rangeslider__handle {
  width: 20px !important;
  height: 20px !important;
}

.rangeslider-horizontal .rangeslider__handle:after {
  width: 10px !important;
  height: 10px !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
}

.input-field-side-text1 {
  /*width: 100px;*/
  margin: auto 20px auto 0;
}

.sub-cat-master-modal .modal-footer {
  display: initial !important;
}

.personal-avatar-user {
  cursor: pointer;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  object-fit: cover;
}

/*Thilina*/
.conterBox {
  height: 50px;
  width: 50px;
  border-radius: 2px solid gray;
  border-width: 2px;
}

.branch-details-card .body {
  min-height: 392px;
}

.cursor-pointer {
  cursor: pointer !important;
}

.selected-li {
  background-color: #007bff;
}

.fancy-radio input[type="radio"]:checked + span i:after {
  background-color: #007bff;
}

.badge {
  margin-bottom: 5px !important;
}

.rangeslider-horizontal .rangeslider__fill {
  background-color: #00bcd2 !important;
}

.link {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}

.link:hover,
.link:focus {
  color: #437ac7;
  text-decoration: none;
  cursor: pointer;
}

.user-edit-form .input-field-side-text {
  width: 180px;
}

.user-edit-form .form-control {
  height: calc(1.5em + 0.5rem + 2px);
}

.user-edit-form .form-group {
  margin-bottom: 1rem;
}

.center-div {
  height: 100%;
  align-content: center;
}

.center-div .with-name {
  margin: 0;
  /*padding-left: 0.5rem;*/
  padding: 15px;
  /*display: inline-block;*/
}

.smsTab.active {
  background-color: #17c2d7 !important;
  border-color: transparent !important;
  color: #fff !important;
}

.smsTab {
  background-color: #fff;
  border-radius: 0.1875rem;
  color: #6f7b7d;
  margin-right: 2px;
  border: 2px solid #e2e3e5;
}

.sms-template-td {
  /*white-space: pre-line;*/
  border: 1px solid black;
  width: 50px;
  word-wrap: break-word;
  height: 15px;
  text-wrap: ellipsis;
}

/*start table*/

.smsTable h1,
.smsTable h3 {
  text-align: center;
}

.smsTable {
  /*border-spacing: 0px;*/
  table-layout: fixed;
  /*margin-left: auto;*/
  margin-right: auto;
  color: #77797c;
  border-collapse: separate;
  border-spacing: 0 15px;
}

.smsTable td {
  /*border: 1px solid black;*/
  word-break: break-all;
  /*padding: .35rem;*/
}

.smsIcons {
  text-align: right;
}

/*end table*/
.auth-main .register_div {
  width: 609px;
}

.light_version .card .top-title {
  color: #a5a8ad;
}

.branch-summary-page .donut-1657005146780-text {
  font-size: 16px;
}

.icon-color-white {
  color: #fff;
}

.branch-summary-page .form-group:hover .d-block {
  font-weight: 700;
  cursor: pointer;
}

@media screen and (max-width: 767px) {
  .auth-main .register_div {
    width: 420px;
  }
}

.branchButton {
  position: relative;
  top: 4px;
}

.MX {
  background: #51bcf7;
}

.CNAME {
  background: #1db231;
}

.swatch {
  height: 1em;
  width: 1em;
  margin: 1em;
}

.countLabel {
  position: relative;
  left: 25px;
  top: -25%;
}

/*.tdDonut {*/
/*position: relative;*/
/*left: 10%;*/
/*}*/

/*.btnAction {*/
/*position: relative;*/
/*left: 5%;*/
/*}*/

.counter {
  background: #d2d8da12;
  margin: 5px;
  padding: 10px;
  /* width: 150px; */
  height: 75px;
  cursor: pointer;
  border-radius: 5px;
  text-align: center;
}

.light_version .counter {
  background: #d2d8da;
}

.counter:hover {
  background: #76797a47;
}

.counter.reminder {
  /* border-left: 5px solid #007bff; */
  background-color: #6c757d;
  color: #fff;
}

.counter h3 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.counter h6 {
  font-size: 13px;
  margin: 0;
}

.add-counters-list .col-md-4 {
  padding: 0 5px;
}

.counters-wrapper {
  margin-top: 30px;
  margin-left: 50px;
  width: 400px;
  max-height: 250px;
  overflow: auto;
  display: flex;
  flex-wrap: wrap;
}

/* width */
#scroll-style::-webkit-scrollbar {
  width: 5px;
}

/* Track */
#scroll-style::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
#scroll-style::-webkit-scrollbar-thumb {
  background: rgb(70, 69, 69);
  border-radius: 10px;
}

/* Handle on hover */
#scroll-style::-webkit-scrollbar-thumb:hover {
  background: rgb(70, 69, 69);
}

.category-group-down .badge {
  margin-left: 0px !important;
}

/* common */
.float-right {
  float: right;
}

.float-left {
  float: left;
}

.button1 {
  background: #51bcf7;
  /*border-radius: 50%;*/
  float: left;
  width: 15px;
  height: 15px;
  margin-right: 10px;
}

.button2 {
  background: #1db231;
  /*border-radius: 50%;*/
  float: left;
  width: 15px;
  height: 15px;
  margin-right: 10px;
}

.button3 {
  background: #2c3e2f;
  /*border-radius: 50%;*/
  float: left;
  width: 15px;
  height: 15px;
  margin-right: 10px;
}

.wrapper1 {
  /*float: left;*/
  width: 100%;
  /*margin-bottom: 10px;*/
}

.wrapper1 a {
  float: left;
}

.wrapper1 span {
  position: relative;
  bottom: 3px;
}

.inline-block-child {
  display: inline-block;
}

/*pagination start*/
.pagination {
  margin-left: 35%;
  display: flex;
  list-style: none;
  border-color: #e1e8ed !important;
}

.pagination > .active > a {
  background-color: #17c2d7;
  color: #fff;
}

.pagination > li > a {
  /*border: 1px solid #17C2D7;*/
  border: 1px solid #e1e8ed !important;
  padding: 5px 10px;
  outline: none;
  cursor: pointer;
}

.theme-cyan .pagination > .active > a {
  color: #fff;
}

.pagination > li > a,
.pagination > li > span {
  color: #17c2d7;
  /* background-color: azure; */
}

.pagination > li:first-child > a {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.pagination > li:last-child > a {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.pagination > li > a:focus,
.pagination > li > span:focus {
  background-color: unset;
  border-color: unset;
  color: unset;
}

.pagination > li > a:focus,
.pagination > li.disabled > span:focus {
  background-color: inherit;
  border-color: unset;
  color: #6c757d;
}

/* .light_version .pagination .disabled.page-item .page-link {
    background-color: #e1e8ed;
} */
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background-color: #17c2d7;
  border-color: unset;
  color: #fff;
}

.light_version .pagination .disabled a {
  background-color: #e1e8ed;
  color: #6c757d;
}

/* common */
.text-light-green {
  color: #42deb0;
}

.text-maroon {
  color: #dc3545;
}

.text-diseble {
  color: #404a69f7;
}

.light_version .text-diseble {
  color: #404a694d;
}

.bg-light-green {
  background-color: #42deb0 !important;
}

.f-s-12 {
  font-size: 12px !important;
}

.p-l-5 {
  padding-left: 5px;
}

.p-r-5 {
  padding-right: 5px;
}

.p-l-10 {
  padding-left: 10px;
}

.p-r-10 {
  padding-right: 10px;
}

.display-inline-block {
  display: inline-block;
}

.vertically-align-center-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.vertically-align-center-flex-2 {
  display: flex;
  align-items: center;
}

.horizontal-align-center-flex {
  align-items: center;
}

.vertically-align-center-margin {
  margin: 0 auto;
  width: fit-content;
}

.table-overflow-x-hide {
  overflow-x: hidden;
}

.m-0 {
  margin: 0 !important;
}

.cursor-pointer {
  cursor: pointer;
}

.custom-text-label {
  width: 150px;
}

/*branch-dashboard */
.branch-dashboard .card {
  margin-bottom: 10px;
}

.branch-dashboard .top_counter .icon {
  background: #f7f7f7;
  border-radius: 0.1875rem;
  float: left;
  width: 40px;
  height: 40px;
  position: relative;
  margin-top: 4px;
}

.branch-dashboard .top_counter .icon i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.branch-dashboard .card .content .number i {
  font-weight: 300;
  margin-right: 5px;
  color: #54555670;
}

.data-with-icon {
  display: inline-block;
}

.ticket-card-bg {
  border: 1px solid #e1e8ed;
  background-color: #fff !important;
}

.card-with-right-donut {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.card-with-right-donut-region {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.branch-dashboard .c_grid .circle {
  width: 60px;
  height: 60px;
}

.branch-dashboard .media {
  align-items: center;
  justify-content: space-between;
}

.details-page .nav.nav-tabs {
  justify-content: end;
}

.details-page {
  overflow-y: auto;
  overflow-x: hidden;
}

.megamenu.open {
  z-index: 99 !important;
}

.tooltip {
  background-color: initial !important;
  border: none !important;
}

.tooltip-inner {
  background-color: #343a40;
}

.bs-tooltip-top .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #343a40;
}

.details-page .tab-content {
  margin-top: 0px;
}

.icon-branch-management:before {
  content: "\f126";
  font-family: "FontAwesome";
  font-style: normal;
}

.icon-branch-summary:before {
  content: "\f573";
  font-family: "FontAwesome";
  font-style: normal;
}

.icon-sms-template:before {
  content: "\f27b";
  font-family: "FontAwesome";
  font-style: normal;
}

.icon-chart:before {
  content: "\f201";
  font-family: "FontAwesome";
  font-style: normal;
}

.icon-tsu-profile:before {
  content: "\f2bc";
  font-family: "FontAwesome";
  font-style: normal;
}

.icon-notification:before {
  content: "\f0a2";
  font-family: "FontAwesome";
  font-style: normal;
}

.icon-branch:before {
  content: "\f009";
  font-family: "FontAwesome";
  font-style: normal;
}

/* ticket-serving-details-page */
.ticket-serving-details-page .c_grid .circle {
  width: 80px;
  height: 80px;
}

.ticket-serving-details-page .media {
  margin-bottom: 0;
}

/*add branch modal*/
.add-branch-modal .input-field-side-text {
  width: 155px;
}

.table-item-disabled {
  color: #d0d0d0;
}

.table-item-btn-disabled:hover {
  cursor: not-allowed !important;
  color: #d0d0d0;
}

.btnActive {
  background-color: red;
}

.table-item-btn-disabled i {
  color: #d0d0d0;
}

.ribbon .ribbon-box.red {
  background-color: #e15858;
}

.ribbon .ribbon-box.red::before {
  border-color: #e15858;
  border-right-color: transparent;
}

.manager-data-ellipsis {
  white-space: nowrap;
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.l-h-1 {
  line-height: 1;
}

.map-card {
  overflow: hidden;
  border: 1px solid #e1e8ed;
}

/* queue type */
.queue-type-cards .card.active .body {
  background-color: #4890dd;
  color: #ffffff;
}

/* kiosks Design */
.kiosks-canva {
  text-align: center;
  position: relative;
  margin-bottom: 25px;
}

.kiosks-canva.add .card img {
  width: 50%;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.kiosks-canva .card {
  margin-bottom: 10px;
}

.kiosks-canva .body {
  height: 185px;
  padding: 12px;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  position: relative;
}

.kiosks-canva .card img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
/* .kiosk-design-options-btn {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 22px;
    height: 22px;
    border-radius: 100%;
}
.kiosk-design-options-btn:hover {
    background-color: #6169614f;
} */
.kiosk-design-options .kiosk-design-options-icon {
  color: #ffffff82;
}
.light_version .kiosk-design-options .kiosk-design-options-icon {
  color: #000;
}
.kiosk-design-options {
  position: absolute;
  top: 5px;
  right: 5px;
  border-radius: 100%;
}
.kiosk-design-options button {
  padding: 2px 9px;
  border-radius: 100%;
  background-color: unset;
  border: none;
}
.kiosk-design-options button:hover {
  background-color: #6169614f;
}
.kiosk-design-options button:focus,
.kiosk-design-options button:active {
  color: unset;
  background-color: #6169614f;
  border-color: unset;
  box-shadow: unset;
}
.link-list-opt {
  list-style: none;
  margin: 0;
  padding: 0;
}
.dropdown-menu-xs.dropdown-menu {
  padding: 0 !important;
  margin: 0;
  overflow: hidden;
  background: #282b2f;
  border: none;
  box-shadow: 0 0 13px 1px #00000059;
}
.light_version .dropdown-menu-xs.dropdown-menu {
  background: #fff;
}
.link-list-opt a {
  display: flex;
  align-items: center;
  padding: 0.625rem 1.25rem;
  font-size: 12px;
  font-weight: 500;
  color: #7b7c7e;
  transition: all 0.4s;
  line-height: 1.3rem;
  position: relative;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  width: 100%;
}
.link-list-opt a:hover,
.link-list-opt a:active {
  color: #d5d3d3;
  text-decoration: unset;
  background-color: #f8f9fa0d;
}
.light_version .link-list-opt a:hover,
.light_version .link-list-opt a:active {
  color: #000;
  text-decoration: unset;
  background-color: #e3e3e3bf;
}
/* .link-list-opt a:active {
    color: unset;
    text-decoration: unset;
    background-color: unset;
} */

/* AddNewKiosksDesignModal */
.kiosksdesign-label {
  width: 80px;
}

.orient-box-vertical {
  width: 120px;
  height: 140px;
  /* border-style: solid; */
  border: 1px solid #e1e8ed;
  margin-right: 20px;
  border-radius: 5px;
}

.orient-box-vertical:hover,
.orient-box-horizontal:hover {
  cursor: pointer;
  border-color: #1b69bb;
}

.orient.disable .orient-box-vertical:hover,
.orient.disable .orient-box-horizontal:hover {
  cursor: initial;
  border-color: #e1e8ed;
}
.orient.disable .orient-box-vertical.selected:hover,
.orient.disable .orient-box-horizontal.selected:hover {
  cursor: initial;
  border-color: #1b69bb;
}

.orient-box-horizontal {
  width: 160px;
  height: 120px;
  /* border-style: solid; */
  border: 1px solid #e1e8ed;
  border-radius: 5px;
}

.orient {
  display: flex;
  align-items: center;
  justify-content: center;
}

.orient .orient-box-horizontal.selected,
.orient .orient-box-vertical.selected {
  border-color: #1b69bb;
}

/* AddKiosksModal */
.kiosks-add-form .input-field-side-text {
  width: 160px;
}

/* kiosks create */
.create-kio-temp-left {
  border-radius: 5px;
  padding: 15px 0px 0px;
}

.light_version .create-kio-temp-left {
  border: 1px solid #e1e8ed;
  background-color: #d5d2d252;
}

.create-kio-temp-left {
  background-color: #282b2f;
  border-top: 1px solid rgba(255, 255, 255, 0.09);
}

.left-action-con {
  padding: 0 10px;
}

.screen-list {
  height: 64vh;
  padding: 0px 10px;
}

.scroll-styled-y {
  overflow-y: scroll;
  overflow-x: hidden;
}

.scroll-styled-y::-webkit-scrollbar {
  width: 3px;
}

.scroll-styled-y::-webkit-scrollbar-thumb {
  background: rgba(136, 136, 136, 0.295);
}

.scroll-styled-y::-webkit-scrollbar-thumb:hover {
  background: #888;
}

.screen-list.vertically .screen-list-item-img-cont {
  height: 165px;
}

.screen-list.horizontal .screen-list-item-img-cont {
  height: 90px;
}

.create-kio-temp-left .icon-item {
  width: 18px;
  height: 18px;
  cursor: pointer;
  display: inline-block;
  margin: 0 5px;
}

.create-kio-temp-left .icon-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.screen-list-item.active .screen-list-item-img-cont {
  border: 3px #45a5f5 solid;
}

.screen-list-item-img-cont {
  width: fit-content;
  margin: 0 auto;
  border: 1px #e6e8ed solid;
  border-radius: 5px;
  background-color: #fff;
}

.screen-list-item-img-cont img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.screen-name {
  font-size: 12px;
}

.create-kio-temp-center {
  height: 73vh;
}

.create-kio-temp-right {
  background-color: #282b2f;
  border-top: 1px solid rgba(255, 255, 255, 0.09);
  height: 73vh;
  border-radius: 5px;
  overflow-x: hidden;
  position: relative;
}

.create-kio-temp-right .components,
.create-kio-temp-right .styles {
  right: -500px;
  transition: all 0.3s linear;
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100%;
  padding: 15px 10px;
}

.create-kio-temp-right .components.active {
  right: 0px;
}

.create-kio-temp-right .styles.active {
  right: 0px;
}

.light_version .create-kio-temp-right {
  border: 1px solid #e1e8ed;
  background-color: #d5d2d252;
}

.img-collection-cont {
  border-radius: 5px;
}

.light_version .img-collection-cont {
  border-radius: 0;
  border-bottom: 1px solid #cccccc;
}

.img-collection .row {
  margin: 0;
}

.img-collection .custom-col {
  padding: 0 4px;
}

.background-img-container {
  margin-bottom: 8px;
  position: relative;
}

.background-img-container .close-i-cont {
  position: absolute;
  top: 0px;
  right: 0px;
  background-color: #fff;
  width: 9px;
  height: 8px;
  border-radius: 100%;
  display: none;
  z-index: 999;
}

.background-img-container:hover .close-i-cont {
  display: block;
}

.background-img-container .close-i-cont i {
  position: absolute;
  top: 45%;
  left: 49%;
  transform: translate(-50%, -50%);
  font-size: 12px;
  line-height: 10px;
  cursor: pointer;
  color: #616983;
}

.background-img {
  border-radius: 3px;
  cursor: pointer;
  position: relative;
  width: 100%;
  height: 35px;
}

.background-img.active {
  border: 1px #45a5f5 solid;
}

.background-img img {
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.background-img-container .background-img-name {
  font-size: 10px;
  text-align: center;
  /* margin-top: 4px;
    margin-bottom: 0; */
  width: 90%;
  margin: 4px auto 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.img-collection-title-cont {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px 8px;
  border-top: 1px solid rgba(255, 255, 255, 0.09);
  border-radius: 5px;
}

.img-collection-title-cont p {
  margin-bottom: 0;
  font-size: 12px;
}

.icon-cont label {
  margin: 0;
  cursor: pointer;
}

.icon-cont img {
  width: 17px;
}

.img-collection {
  max-height: 140px;
  padding: 5px 4px 0;
}

.img-collection.no-fix-height {
  max-height: unset;
}

.kiosk-design-right-accordion.accordion button {
  width: 100%;
  border: none;
  background-color: inherit;
  padding: 0;
  border-radius: 5px;
}
.kiosk-design-right-accordion.accordion button:focus {
  outline: none;
}
.light_version .kiosk-design-right-accordion.accordion button {
  color: #212834;
}
.kiosk-design-right-accordion.accordion button {
  color: #bbbec2;
}
.light_version .kiosk-design-right-accordion.accordion > div {
  margin-bottom: 0;
}
.kiosk-design-right-accordion.accordion > div {
  margin-bottom: 10px;
}

.list-group-item.active {
  background-color: #414548;
  border-color: #414548;
}

.light_version .list-group-item.active {
  background-color: #3c89da;
  border-color: #3c89da;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  background: rgba(136, 136, 136, 0.295);
}

::-webkit-scrollbar-thumb:hover {
  background: #888;
}

.form-control:disabled,
.form-control[readonly],
.custom-select:disabled {
  background-color: #575b5e12;
}

.no-click {
  pointer-events: none;
}

.btn.disabled,
.btn:disabled {
  opacity: 0.95;
  pointer-events: none;
  cursor: not-allowed;
}

.btn.disabled:hover,
.btn:disabled:hover {
  border-color: #2f3338;
  box-shadow: none;
}

.light_version .btn.disabled:hover,
.light_version .btn:disabled:hover {
  border-color: #e1e8ed;
  box-shadow: none;
}

.btn.disabled i,
.btn:disabled i {
  opacity: 0.25;
}

.light_version .btn.disabled i,
.light_version .btn:disabled i {
  opacity: 0.65;
}

.row-text-diseble .badge-success {
  background: transparent;
  border-color: #5cb65f80;
  color: #5cb65f80;
}

.row-text-diseble .badge-danger {
  background: transparent;
  border-color: #e1585894;
  color: #e1585894;
}

.icon-container-item {
  display: inline-block;
  padding: 2px 5px;
  margin-right: 2px;
  border-radius: 2px;
}

.icon-container-item:last-child {
  margin-right: 0px;
}

.icon-container-item:hover {
  background-color: #0000002b;
  cursor: pointer;
}

.btn-align-icon {
  width: 17px;
}

.kiosk-template-input {
  align-items: center;
}

.kiosk-template-input .input-label {
  font-size: 10px !important;
  line-height: 10px !important;
}

.kiosk-template-input input,
.kiosk-template-input select {
  font-size: 10px !important;
  line-height: 10px !important;
  height: 20px !important;
  padding: 0.25rem 0.2rem !important;
  color: #77797c;
}

.light_version .kiosk-template-input input,
.light_version .kiosk-template-input select {
  background-color: #00000014 !important;
}

.light_version .kiosk-template-input input:focus,
.light_version .kiosk-template-input select {
  border: 1px solid #ced0d1;
}

.props-container {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: rgba(255, 255, 255, 0.1);
  padding-bottom: 10px;
}

.light_version .props-container {
  border-color: #ced0d1;
}

.progress-cont .progress {
  width: 100%;
  height: 2px;
}

.icons-cont-item-link:hover {
  color: #fff;
  text-decoration: underline;
  cursor: pointer;
}
.template-options {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #302f2f;
  border-radius: 3px;
  transform: scaleY(0);
  transition: 0.4s all ease-out;
  -webkit-transform-origin: 0 0 0;
  transform-origin: 0 0 0;
}
.light_version .template-options {
  background-color: #000000a1;
}
.kiosks-canva .card:hover .template-options {
  transform: scaleY(1);
}
.light_version .icons-cont-item-link {
  color: #cbcbcb;
}
.light_version .icons-cont-item-link:hover {
  color: #fff;
}
.icons-cont {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: max-content;
}

.icons-cont i {
  font-size: 25px;
  color: #a9a6a6;
  cursor: pointer;
}

.icons-cont i:hover {
  color: #fff;
}

.input-kiosks-temp-rename {
  width: 100%;
  border: none;
  background-color: unset;
  text-align: center;
  color: inherit;
  border: 1px solid #6c6b6b1a;
  cursor: auto;
  font-size: 12px;
}

.input-kiosks-temp-rename:focus {
  outline: none;
  /*border: 1px solid #e3e3ee; */
  /* border: 1px solid rgba(255,255,255,0.1); */
}

.input-kiosks-temp-rename[readonly] {
  border: none;
}

/* Addskillsmodal */
.header.skill-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #17c2d7;
}

.selecte-all-container {
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 11px;
}

.no-values-text {
  font-size: 12px;
}

.no-values-text.kiosks-img {
  text-align: center;
  margin: 0 auto 20px;
}

/* swal */
.swal2-popup {
  background: #222121 !important;
}

.light_version .swal2-popup {
  background: #fff !important;
}

.swal2-popup .swal2-styled {
  font-size: 14px !important;
}

.swal2-popup .swal2-styled:focus {
  box-shadow: none !important;
}

.swal2-popup .swal2-title {
  font-size: 14px !important;
  padding: 15px 0 0 !important;
}

/* swal-icon */
.swal2-container .swal2-icon {
  width: 4em !important;
  height: 4em !important;
  margin: 1.25em auto 1em !important;
  border: 0.13em solid transparent !important;
  border-radius: 50%;
}

.swal2-icon::before {
  font-size: 2.5em !important;
}

.swal2-icon .swal2-icon-content {
  font-size: 2.5em !important;
}

/* categoryassignpage */

.card-counter.active .body {
  background-color: #4890dd;
  color: #f1f4f6;
}

.selecte-checkbox {
  position: absolute;
  right: 0px;
  top: 8px;
}

.from-counter {
  padding-left: 5px;
  padding-right: 5px;
}

.card {
  margin-bottom: 15px;
  font-weight: 100;
}

#external-link {
  position: absolute;
  top: 10px;
  right: 10px;
}

.assign-cat-left {
  height: 74vh;
  overflow-y: scroll;
  padding: 0 8px 0 8px;
}

.assign-cat-right {
  height: 74vh;
  overflow-y: scroll;
  padding: 0 15px 0 8px;
}

/* .light_version .card .body {
    color: #f1f4f6;
} */

.f-w-400 {
  font-weight: 400;
}

.advance-view-close {
  color: #616983;
  cursor: pointer;
}

.color-view-box {
  border: "1px solid #393d42";
}

.light_version .color-view-box {
  border: "1px solid #e1e8ed";
}

.switch.sm-switch {
  width: 25px;
  height: 15px;
}

.switch.sm-switch .slider:before {
  height: 9px;
  width: 13px;
  left: 4px;
  bottom: 3px;
}

.switch.sm-switch input:checked + .slider:before {
  transform: translateX(5px);
}

.advance-view-title {
  font-size: 11px;
}

.kiosk-template-label {
  font-size: 10px !important;
  line-height: 10px !important;
}

.fancy-radio.custom input[type="radio"]:checked + span i:after {
  border-radius: 50%;
  display: block;
  position: relative;
  top: 3px;
  left: 3px;
  content: "";
  width: 8px;
  height: 7px;
  background-color: #7b848c;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-51%, -54%);
}

.fancy-radio.custom input[type="radio"]:checked + span i:after {
  background-color: #17c2d7;
}

.fancy-radio.custom input[type="radio"] + span i {
  width: 13px;
  height: 13px;
}

.fancy-radio.custom label {
  margin-bottom: 0;
}

.light_version .fancy-radio.custom input[type="radio"] + span i {
  border-color: #a9b2b9;
}

.badge.kiosks-badge {
  font-size: 60%;
}

.list-group-item.active {
  z-index: 0;
  /* BranchTables */
}

.summary-items {
  display: flex;
  justify-content: space-between;
}

.card .header .ml-1 {
  margin-left: 57.25rem !important;
  margin-bottom: 0px;
}

.card .header h2 {
  color: #17c2d7;
  font-weight: 500;
}

.summary-items.localWT {
  color: #17c2d7;
  cursor: pointer;
}

.accordion-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #7b848c;
  padding: 0px 0px;
}

.accordion-header h2 {
  font-size: 15px;
  font-weight: 500;
  margin: 0;
  padding: 5px 0;
}

.table-responsive-sms {
  padding-top: 11px;
}

.disable-kiosks-temp-tool {
  pointer-events: none;
  color: #d0d0d012;
}

.disable-kiosks-temp-tool,
.disable-kiosks-temp-tool input,
.disable-kiosks-temp-tool select {
  color: #d0d0d012;
}

.light_version .disable-kiosks-temp-tool,
.disable-kiosks-temp-tool input,
.light_version .disable-kiosks-temp-tool select {
  color: #77797c4f !important;
}

.disable-kiosks-temp-tool img,
.disable-kiosks-temp-tool .color-view-box {
  opacity: 0.2;
}

.light_version .disable-kiosks-temp-tool img,
.light_version .disable-kiosks-temp-tool .color-view-box {
  opacity: 0.2;
}

.disable-kiosks-temp-tool
  .list-group
  .list-group-item.kiosk-template-label.active {
  background-color: #41454852;
  border-color: #414548;
  color: #ffffff36;
}

.light_version
  .disable-kiosks-temp-tool
  .list-group
  .list-group-item.kiosk-template-label.active {
  background-color: #3c89da36;
  border-color: #3c89da0d;
  color: #fff;
}

.kiosks-build-tool .kiosks-custom-col-2 {
  flex: 0 0 20%;
  max-width: 20%;
  padding-right: 2px;
}

.kiosks-build-tool .kiosks-custom-col-7 {
  flex: 0 0 60%;
  max-width: 55%;
  padding: 0 2px;
}

.kiosks-build-tool .kiosks-custom-col-3 {
  flex: 0 0 25%;
  max-width: 25%;
  padding-left: 2px;
}

.kionsks-empty-value-para {
  font-size: 10px;
}

.table-responsive-sms {
  padding-top: 11px;
}

/* userskillspage */
.badge:empty {
  display: flex;
  padding: 8px 10px 8px 10px;
}

.label {
  display: inline-block;
}

.right-custom-nav li {
  cursor: pointer;
  text-transform: capitalize;
}

.right-custom-nav li:hover {
  background-color: #414548;
  border-color: #414548;
}

.right-custom-nav li label {
  margin: 0;
}

.right-custom-nav li.active {
  background-color: #414548;
  border-color: #414548;
}

.right-custom-nav li.active:hover {
  background-color: #414548;
  border-color: #414548;
}

.right-custom-nav li:hover {
  background-color: #41454852;
  border-color: #41454852;
}

.right-custom-nav li label {
  margin: 0;
}

.light_version .right-custom-nav li.active {
  background-color: #3c89da;
  border-color: #3c89da;
}

.light_version .right-custom-nav li:hover {
  background-color: #ebebebad;
  border-color: #e1e8ed;
}

.light_version .right-custom-nav li.active:hover {
  background-color: #3c89da;
  border-color: #3c89da;
}

.light_version .right-custom-nav li.active .badge-info {
  border-color: #fff;
  color: #fff;
}

.ribbon .ribbon-box {
  color: #fff;
  right: auto;
  left: 0;
  top: 8px;
  padding-left: 8px;
  padding-right: 4px;
  position: absolute;
  height: 32px;
  line-height: 35px;
}

.layers-custom-separeter {
  height: 1px;
  background: #ffffff2e;
  margin: 10px 0;
}

.light_version .layers-custom-separeter {
  background: #cccccc;
}

.nestable-item,
.nestable-item-copy {
  margin: 3px 0 0 !important;
}

.remove-text {
  color: #e54c4c;
}

.remove-text:hover {
  text-decoration: underline;
  cursor: pointer;
}

.alert-custom {
  color: #6c757d;
  background-color: #414548;
  border-color: #414548;
}

.light_version .alert-custom {
  color: #6c757d;
  background-color: #385e7a0d;
  border-color: #385e7a0d;
}

.alert-custom .close {
  text-shadow: none;
}

.alert-custom .close:hover {
  color: #fff;
  text-decoration: none;
}

.light_version .alert-custom .close:hover {
  color: #000;
  text-decoration: none;
}

.card-disable {
  opacity: 0.3;
  pointer-events: none;
}

/* tsuprofile */
.btn-block {
  display: block;
  width: 100%;
  padding: 8px 11px;
}

.card-header-align {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.theme-cyan .card .header h2 {
  color: #9a9a9a;
  font-weight: 400;
}

.card .header .header-dropdown {
  position: relative;
  top: 0;
  right: 0px;
  list-style: none;
}

.card .header .header-dropdown {
  color: #a5a8ad;
}

.tableFixHead {
  overflow: auto;
  height: 70vh;
}

.tableFixHead thead th {
  position: sticky;
  top: 0;
  z-index: 1;
}

/* Just common table stuff. Really. */
table {
  border-collapse: collapse;
  width: 100%;
}

th,
td {
  padding: 8px 16px;
}

th {
  background: #22252a;
}

.click-card-list .card {
  cursor: pointer;
}

.click-card-list .card.active .body {
  background-color: #3c89da;
  border-color: #3c89da;
  color: #ffffff;
}

.click-card-list .card:hover .body {
  background-color: #41454852;
  border-color: #41454852;
}

.click-card-list .card.active:hover .body {
  background-color: #414548;
  border-color: #414548;
}

.light_version .click-card-list .card.active .body {
  background-color: #3c89da;
  border-color: #3c89da;
  color: #ffffff;
}

.light_version .click-card-list .card:hover .body {
  background-color: #ebebebad;
  border-color: #e1e8ed;
}

.click-card-list .card.active:hover .body {
  background-color: #3c89da;
  border-color: #3c89da;
}

.light_version .click-card-list .card.active .progress {
  background-color: #999ca0fa;
}

.light_version .click-card-list .card.active .bg-azura {
  background: #fff;
}

.kiosk-design .body {
  padding: 10px 5px;
  border: none;
}

.kiosk-design .body img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.kiosk-design-thumbnail {
  height: 130px;
  width: 130px;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
}

.kiosk-design .manager-data-ellipsis {
  max-width: 85px;
}

.kiosk-design.active .body {
  border: 1px solid rgba(255, 255, 255, 0.09);
}

.owl-theme .owl-nav {
  position: absolute;
  margin-top: 0px !important;
  top: 33%;
  transform: translateY(-50%);
  width: 100%;
}

.owl-carousel .owl-nav button.owl-next {
  float: right;
  right: -40px;
  position: relative;
}

.owl-carousel .owl-nav button.owl-prev {
  float: left;
  left: -40px;
  position: relative;
}

.owl-theme .owl-nav [class*="owl-"]:hover {
  background: unset !important;
}

.owl-carousel .owl-nav button:focus {
  outline: none;
}

.owl-theme .owl-dots .owl-dot span {
  background: #869791 !important;
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background: #ffffff !important;
}

.btn-action-types-container {
  display: flex;
  justify-content: space-around;
}

.ticket-design-figure {
  position: relative;
  width: 320px;
  height: 200px;
}

.headerImage-figcaption {
  cursor: pointer;
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100%;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0);
  transition: all ease-in-out 0.3s;
}

.headerImage-figcaption:hover {
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.5);
}

.headerImage-figcaption > img {
  /* margin-top: 100px; */
  width: 20px;
  height: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.headerImage-avatar {
  width: 100%;
  height: 100%;
  object-fit: contain;
  cursor: pointer;
  /* height: 200px; */
  border-radius: 5%;
  border: 2px solid transparent;
  transition: all ease-in-out 0.3s;
}

.headerImage-avatar:hover {
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
}

.headerImage-avatar-user {
  cursor: pointer;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  object-fit: cover;
}

.footerImage-figcaption {
  cursor: pointer;
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100%;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0);
  transition: all ease-in-out 0.3s;
}

.footerImage-figcaption:hover {
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.5);
}

.footerImage-figcaption > img {
  /* margin-top: 100px; */
  width: 20px;
  height: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.footerImage-avatar {
  width: 100%;
  height: 100%;
  object-fit: contain;
  cursor: pointer;
  /* height: 200px; */
  border-radius: 5%;
  border: 2px solid transparent;
  transition: all ease-in-out 0.3s;
}

.footerImage-avatar:hover {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.5);
}

.footerImage-avatar-user {
  cursor: pointer;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  object-fit: contain;
}

.identification-active {
  background-color: #007bff;
  color: #f4eeee;
}

.user-account .user_div .user-photo {
  width: 40px;
  height: 40px;
  object-fit: cover;
}

.mtt-time {
  display: inline-block;
  margin-right: 30px;
}

.category-master-account {
  color: #96989c;
}

/* .fa-times-circle:hover {
    scale: 1.5;
    transition-duration: 0.2s;
} */
.clickable {
  cursor: pointer;
}

.img-input-field {
  border: none;
  padding: 0;
  height: 100%;
}

.function {
  display: block;
  font-size: 13px;
  color: #a5a8ad66;
}

/* branchtable */
.branch-tables-container .accordion-body {
  padding: 20px 20px 0;
}

.light_version .branch-tables-container .accordion-body {
  padding: 0;
}

.light_version .branch-tables-container .accordion-item {
  margin-bottom: 20px;
}

.Toastify__toast--dark {
  background: #363636 !important;
  color: #fff !important;
  border-radius: 10px !important;
}

.Toastify__progress-bar--dark {
  background: #9a57ed;
}

.Toastify__toast--success {
  background: #12120d !important;
  color: #07bc0c !important;
  border-radius: 10px !important;
}

.Toastify__toast--warning {
  background: #12120d !important;
  color: yellow !important;
  border-radius: 10px !important;
}

.Toastify__toast--error {
  background: #12120d !important;
  color: red !important;
  border-radius: 10px !important;
}

.fancy-checkbox-end {
  display: flex;
  align-items: center;
  justify-content: end;
}

.read-more-link {
  color: lightskyblue !important;
  text-decoration: underline !important;
  letter-spacing: 1px !important;
  cursor: pointer;
  display: flex !important;
  padding: 13px 20px 5px !important;
  align-items: center !important;
  justify-content: end !important;
}

.icon-notificationtypekiosk {
  background-color: #e15858;
  color: rgba(255, 255, 255, 0.7);
}

.icon-notificationtypeservice {
  background-color: #588de1;
  color: rgba(255, 255, 255, 0.7);
}

.icon-notificationtypewaiting {
  background-color: #e1bc58;
  color: rgba(255, 255, 255, 0.7);
}

.text-notificationtypekiosk {
  color: #e15858 !important;
}

.text-notificationtypeservice {
  color: #588de1 !important;
}

.text-notificationtypewaiting {
  color: #e1bc58 !important;
}

.navbar-nav .dropdown-menu {
  top: 94% !important;
}

.text-fit-to-button {
  padding: 8px 0;
  display: inline-block;
}

.breadcrumb-item.link {
  color: #007bff;
}

.breadcrumb-item.link:hover {
  color: #437ac7;
}

.circle.sm {
  width: 80px;
  height: 80px;
}

.card-user-no {
  font-size: 14px;
  font-weight: 300;
}

.card-user-name {
  white-space: nowrap;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.accordion-body {
  padding: 20px 10px 0;
}

.operator-details-content {
  border-radius: 3px;
  font-weight: 400;
  padding: 22px;
}

.operator_wizard_tab {
  background-color: #414548;
  border-radius: 0.1875rem;
  color: #fff;
  margin-right: 2px;
}
.operator_wizard_tab:hover {
  background-color: #464b4e52;
  border-radius: 0.1875rem;
  color: #fff;
  margin-right: 2px;
}
.operator_wizard_tab.active {
  color: #464b4e52;
  background-color: #a5a8ad !important;
  border-color: #eb0f6b !important;
  color: #fff !important;
}
.operator_wizard_tab:focus {
  color: #fff !important;
}
.stop-table-col-overflow .table tr td,
.stop-table-col-overflow .table tr th {
  vertical-align: baseline !important;
  white-space: break-spaces !important;
}
.user-card-fit-width {
  width: fit-content;
  margin: 0 auto;
}
.MuiPaper-root.MuiPaper-elevation {
  background-color: #2f3338 !important;
  color: #a5a8ad !important;
}
.MuiPaper-root.MuiPaper-elevation.MuiPopover-paper {
  padding: 15px !important;
}
.MuiPaper-root.MuiPaper-elevation.MuiPopover-paper .MuiFormControlLabel-label {
  color: #a5a8ad !important;
}
.light_version
  .MuiPaper-root.MuiPaper-elevation.MuiPopover-paper
  .MuiFormControlLabel-label {
  color: #000000de !important;
}
.light_version .MuiPaper-root.MuiPaper-elevation {
  background-color: #fff !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.css-1ex1afd-MuiTableCell-root,
.MuiTableCell-root.MuiTableCell-body {
  color: #a5a8ad !important;
  border-bottom: 1px solid #e0e0e012 !important;
}
.light_version .css-1ex1afd-MuiTableCell-root,
.light_version .MuiTableCell-root.MuiTableCell-body {
  color: rgba(0, 0, 0, 0.87) !important;
  border-bottom: 1px solid #e0e0e0 !important;
}
.MuiTableCell-root.MuiTableCell-footer {
  border-bottom: none !important;
}
.MuiButtonBase-root.MuiIconButton-root {
  color: #938f8f8a !important;
}
.MuiButtonBase-root.MuiIconButton-root:hover {
  background-color: #7e7e7e1f;
}
.tss-1akey0g-MUIDataTableHeadCell-data {
  font-size: 12px;
  line-height: 17px;
}
.MuiTableCell-root.MuiTableCell-head {
  color: #ffffffde !important;
  background-color: #505050 !important;
  border: none !important;
}
.light_version .MuiTableCell-root.MuiTableCell-head {
  color: #000000de !important;
  background-color: #d1d1d1 !important;
}
.MuiTypography-root.MuiTypography-body2 {
  color: #938f8f8a !important;
}
.light_version .MuiTypography-root.MuiTypography-body2 {
  color: #000000de !important;
}
/* .css-hfutr2-MuiSvgIcon-root-MuiSelect-icon {
    color: #938f8f8a !important;
}
.light_version .css-hfutr2-MuiSvgIcon-root-MuiSelect-icon {
    color: #000000de !important;
} */
.MuiTablePagination-selectLabel {
  color: #938f8f8a !important;
}
.light_version .MuiTablePagination-selectLabel {
  color: #000000de !important;
}
.css-194a1fa-MuiSelect-select-MuiInputBase-input {
  color: #938f8f8a !important;
}
.light_version .css-194a1fa-MuiSelect-select-MuiInputBase-input {
  color: #000000de !important;
}
/* .css-pqjvzy-MuiSvgIcon-root-MuiSelect-icon {
    color: #938f8f8a !important;
}
.light_version .css-pqjvzy-MuiSvgIcon-root-MuiSelect-icon {
    color: #000000de !important;
} */
.MuiTablePagination-displayedRows {
  color: #938f8f8a !important;
}
.light_version .MuiTablePagination-displayedRows {
  color: #000000de !important;
}
.MuiSelect-select {
  color: #938f8f8a !important;
}
.light_version .MuiSelect-select {
  color: #000000de !important;
}
.MuiButtonBase-root.MuiButton-root:focus {
  outline: none !important;
}
.tss-bd2lw8-MUIDataTableHeadCell-sortActive {
  color: #fff !important;
}
.light_version .tss-bd2lw8-MUIDataTableHeadCell-sortActive {
  color: #000000de !important;
}
.MuiButtonBase-root.MuiTableSortLabel-root.Mui-active .MuiTableSortLabel-icon {
  opacity: 1;
  color: #33ff0099 !important;
}
.light_version
  .MuiButtonBase-root.MuiTableSortLabel-root.Mui-active
  .MuiTableSortLabel-icon {
  color: #000000de !important;
}
.css-zylse7-MuiButtonBase-root-MuiIconButton-root {
  color: #808080 !important;
  outline: none !important;
}
.light_version .css-zylse7-MuiButtonBase-root-MuiIconButton-root {
  color: inherit !important;
}
.MuiButtonBase-root.Mui-disabled.MuiIconButton-root.Mui-disabled
  .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium {
  color: #8d888830 !important;
}
.light_version
  .MuiButtonBase-root.Mui-disabled.MuiIconButton-root.Mui-disabled
  .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium {
  color: #00000042 !important;
}
.MuiTablePagination-displayedRows {
  margin-bottom: 0px;
}
.MuiTablePagination-selectLabel {
  margin-bottom: 0;
}
.MuiInputBase-root.MuiInput-root.MuiInput-underline:before {
  border-bottom: 1px solid #808080 !important;
  color: #808080 !important;
}
.light_version .MuiInputBase-root.MuiInput-root.MuiInput-underline:before {
  border-bottom: 1px solid #0000006b !important;
}
.MuiInputBase-input.MuiInput-input {
  color: #808080 !important;
}
.light_version .MuiInputBase-input.MuiInput-input {
  color: #000000de !important;
}
.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium {
  color: #8d888899 !important;
}
.light_version .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium {
  color: #00000099 !important;
}
.MuiTypography-root.MuiTypography-caption {
  color: #727272 !important;
}
.light_version .MuiTypography-root.MuiTypography-caption {
  color: #00000099 !important;
}
.MuiButtonBase-root.MuiIconButton-root:focus {
  outline: none !important;
  background-color: rgba(0, 0, 0, 0.04) !important;
}
.accordion .MuiButtonBase-root.MuiIconButton-root {
  width: unset;
  border: unset;
  background-color: unset;
  border-radius: unset;
  padding: 8px;
  border-radius: 50%;
  overflow: visible;
}
.home-card .body {
  padding: 35px 25px;
}
.home-chart-card .body {
  padding: 22px;
}
.operator-status-btn {
  color: #000 !important;
  background-color: #a5a8ad !important;
}
.operator-status-btn:hover {
  color: #000 !important;
  background-color: #a5a8ad !important;
  margin-bottom: 0 !important;
}
.notification-shade-empty {
  text-align: center;
  line-height: 100px;
}
.muidatatablecontainer .MuiTableCell-root.MuiTableCell-body {
  text-align: center;
}
.muidatatablecontainer .tss-178gktx-MUIDataTableHeadCell-contentWrapper {
  justify-content: center;
}
.css-ubpweo-MuiPaper-root-MuiPopover-paper {
  padding: 15px;
  background-color: #504f4f !important;
}
.tss-1h9t3sl-MUIDataTableHeadCell-sortAction {
  align-items: center !important;
}
.MuiTableRow-root.MuiTableRow-hover:hover {
  background-color: #ffffff0d !important;
}
.table-action-btn {
  border-radius: 100%;
  padding: 0 !important;
  width: 27px !important;
  height: 27px !important;
  background-color: #424242b0 !important;
}
.light_version .table-action-btn {
  background-color: transparent !important;
}
.table-action-btn-option-disable {
  pointer-events: none;
  color: #7b7c7e8c !important;
}
.table-text-disable {
  color: #b5b5b552 !important;
  margin: 0;
}
.vip-icon {
  margin-left: 10px;
  color: #ff9901;
}
.table-text-disable .vip-icon {
  color: #ff99018f;
}
/* home-page */
.home-list-card {
  margin-bottom: 3px;
}
.list-action-btn {
  display: inline-block;
  width: 35px;
  height: 33px;
  position: relative;
  padding: 0;
}
.list-action-btn i {
  font-size: 19px;
  line-height: 21px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: (-50%, -50%);
  transform: translate(-50%, -50%);
}
.donut-parent {
  display: flex;
  align-items: center;
  justify-content: center;
}
.wrapper-custom {
  display: flex;
  align-items: center;
}
.input-grouptime {
  display: flex;
  width: 250px;
  justify-content: space-between;
  margin-bottom: 5px;
}
.download {
  display: flex;
  cursor: pointer;
  justify-content: flex-end;
  float: right;
}
.download-icon {
  font-size: 25px;
}
.dropdown-toggle-split {
  padding: 0 0 0 0;
}
.select-branch-dropdown {
  display: inline-block;
  width: 220px;
}
.long-arrow {
  font-size: 25px;
  float: right;
  align-items: center;
}
.Toastify__toast-body {
  padding: 6px !important;
}
.toast-title {
  font-weight: 600;
  text-transform: capitalize;
}
.toast-content {
  margin: 0;
  color: #9d9d9d;
}
.light_version .input-daterange .input-group-addon {
  line-height: 27px;
  color: #000000;
  background: #ced4da;
  text-shadow: none;
}
.input-daterange .input-group-addon {
  line-height: 27px;
  color: #fff;
  background: #626262;
  text-shadow: none;
}
.report-table tr:hover .long-arrow {
  color: #007bff;
}
.logout-icon {
  position: absolute;
  right: 25px;
}
.header-unread-notifi {
  background-color: #e1e1e1b5 !important;
}
li.header.blue {
  margin-bottom: 0 !important;
}
.custom-switch {
  width: 26px !important;
  height: 18px !important;
}
.custom-switch input:checked + .slider:before {
  transform: translateX(7px) !important;
}
.custom-switch .slider:before {
  height: 12px !important;
  width: 12px !important;
}
.custom-switch .slider {
  width: inherit !important;
}
.card-body-dashboard {
  padding: 17.5px 23px !important;
}
.card-body-dashboard h6 {
  margin: 0;
}

.card-body-dashboard-region {
  padding: 12px 23px !important;
}

.vip-aux-padding {
  padding: 10px 22px !important;
}

.vip-aux-padding-region {
  padding: 15px 22px !important;
}

.modal-fix-height {
  max-height: 350px;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 0 5px 0 0;
}
.select-btn {
  color: rgb(162, 162, 162);
}
.select-btn:hover {
  background-color: #00bcd2;
}
.metismenu ul a:hover:before {
  width: 22px !important;
}
.circle.sms-group-users {
  width: 80px;
  height: 80px;
}
select.form-control:not([size]):not([multiple]) {
  height: 31px !important;
}
.sms-group-user-card {
  display: flex;
  align-items: center;
  padding: 6px 10px;
  border-radius: 3px;
  margin-bottom: 10px;
  cursor: pointer;
}
.sms-group-user-card:hover {
  background-color: #41454878;
  border-color: #41454878;
}
.sms-group-user-card.active {
  background-color: #414548;
  border-color: #414548;
}
.sms-group-user-card .user-avatar {
  width: 35px;
  height: 35px;
  border-radius: 100%;
}
.sms-group-user-card .name-text {
  max-width: 95px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.sms-group-modal-body .input-field-side-text {
  width: 110px;
}
.report-accordion > div {
  margin-bottom: 10px;
}
.light_version .report-accordion > div {
  margin-bottom: 10px;
}
.light_version
  .report-accordion
  .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded {
  box-shadow: none;
}
.external-link-active {
  color: #000000;
  background-color: #00bcd2;
}
.shimmer.shimmer-thumbnail {
  background: linear-gradient(
    to right,
    #282b2fa3 8%,
    #282b2fc7 18%,
    #282b2f 33%
  );
  background-size: 1000px 100%;
  animation: shimmer2 2.2s linear infinite forwards !important;
}
.light_version .shimmer.shimmer-thumbnail {
  background: linear-gradient(to right, #f6f6f6 8%, #f0f0f0 18%, #f6f6f6 33%);
  background-size: 1000px 100%;
  animation: shimmer2 2.2s linear infinite forwards !important;
}
@-webkit-keyframes shimmer2 {
  0% {
    background-position: -100% 0;
  }
  100% {
    background-position: 100% 0;
  }
}
@keyframes shimmer2 {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}

.acc-color-branch {
  background-color: #818f9e;
  color: rgb(253, 253, 253) !important;
}
.light_version .acc-color-branch {
  background-color: #cce5ff;
  color: #475666 !important;
}
.acc-color-district {
  background-color: #5a7796;
  color: rgb(253, 253, 253) !important;
}
.light_version .acc-color-district {
  background-color: #cce5ff;
  color: #475666 !important;
}
.acc-color-province {
  background-color: #3a6593;
  color: rgb(253, 253, 253) !important;
}
.light_version .acc-color-province {
  background-color: #cce5ff;
  color: #475666 !important;
}
.acc-color-country {
  background-color: #205893;
  color: rgb(253, 253, 253) !important;
}
.light_version .acc-color-country {
  background-color: #cce5ff;
  color: #475666 !important;
}
.modal-lg-select-card-text {
  white-space: nowrap;
  max-width: 253px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
}
.report-download-btn {
  font-size: 11px !important;
  padding: 2px 10px !important;
  margin-right: 20px !important;
}
.light_version .report-download-btn {
  background-color: #a3a3a86e !important;
  border-color: #a3a3a86e !important;
}
.light_version .report-download-btn:hover {
  background-color: #a3a3a3 !important;
  border-color: #a3a3a3 !important;
}
.select-region-checkbox {
  margin: 0;
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
}
.select-region-checkbox span:before {
  margin: 0 !important;
}
.position-relative {
  position: relative;
}
.region-sort-ellipsis {
  width: 160px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.button-animated {
  position: relative;
  width: fit-content;
  /* height: 40px; */
  cursor: pointer;
  display: flex;
  align-items: center;
  border: 1px solid #17c2d7;
  border-radius: 5px;
  background-color: #17c2d7;
  overflow: hidden;
  padding: 1px 30px 1px 10px;
  margin: 0 5px 5px 0;
}
.button-animated,
.button__icon,
.button__text {
  transition: all 0.3s;
}
.button-animated .button__text {
  /* transform: translateX(22px); */
  color: #fff;
  font-weight: 400;
  font-size: 10px;
  text-transform: uppercase;
}
.button-animated .button__icon {
  position: absolute;
  /* transform: translateX(109px); */
  right: 0;
  height: 100%;
  width: 20px;
  background-color: #0e8d9d;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}
.button-animated .svg {
  width: 20px;
  fill: #fff;
}
.button-animated:hover {
  background: #17c2d7;
}
.button-animated:hover .button__text {
  color: transparent;
}
.button-animated:hover .button__icon {
  width: 100%;
  transform: translateX(0);
}
.button-animated:active .button__icon {
  background-color: #0e8d9d;
}
.button-animated:active {
  border: 1px solid #17c2d7;
}
.button-animated:focus {
  outline: none !important;
}
.animated-btn-container {
  display: flex;
  flex-wrap: wrap;
  min-height: 24px;
}
.assign-user-skill-cont {
  max-height: 330px;
  overflow-y: scroll;
  overflow-x: hidden;
}
.skill-level-span {
  position: absolute;
  top: -9px;
  right: -6px;
  height: 16px;
  width: 16px;
  background-color: #0069d9;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-radius: 100%;
}

.skill-disable-span {
  position: absolute;
  top: -9px;
  right: -6px;
  height: 16px;
  width: 16px;
  background-color: rgb(60, 77, 95);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-radius: 100%;
}
.user-skill-badge {
  cursor: pointer;
  position: relative;
  margin-bottom: 10px !important;
}
.version-label {
  font-size: 12px;
  color: #a5a8ada8;
}
.auth-main {
  flex-direction: column;
}

.text-center-table .tss-178gktx-MUIDataTableHeadCell-contentWrapper {
  justify-content: center !important;
}
.text-center-table .MuiTableCell-root.MuiTableCell-body {
  text-align: center !important;
}
.text-center-table .MuiTableCell-root.MuiTableCell-head {
  text-align: center;
  line-height: 17px;
}
.notification-table .MuiTableRow-root.MuiTableRow-hover:hover {
  background-color: unset;
}
.sticky {
  position: sticky;
  top: 61px !important;
  z-index: 100;
  margin-top: 3px;
  width: 100%;
  height: 65px;
  background-color: #22252a;
}
.light_version .sticky {
  background-color: #f0f3f9;
}
.block-header-padding {
  padding: 10px 6px 0px 6px;
}
.issued-served-card-body {
  border-radius: 3px;
  font-weight: 400;
  color: #a5a8ad;
  background: #282b2f;
  padding: 6px;
  border-top: 1px solid rgba(255, 255, 255, 0.09);
}
.light_version .issued-served-card-body {
  border-radius: 3px;
  font-weight: 400;
  color: #282b2f;
  background: #fff;
  padding: 6px;
  border-top: 1px solid rgba(255, 255, 255, 0.09);
}
.operator-status-card-body {
  border-radius: 3px;
  font-weight: 400;
  color: #a5a8ad;
  background: #282b2f;
  border-top: 1px solid rgba(255, 255, 255, 0.09);
}
.most-served-tickets-body {
  border-radius: 3px;
  font-weight: 400;
  color: #a5a8ad;
  background: #282b2f;
  padding: 18px;
  border-top: 1px solid rgba(255, 255, 255, 0.09);
}
.most-served-tickets-body .table td,
.table th {
  margin: 0px !important;
  padding: 8px !important;
}
.most-served-count-body {
  display: flex !important;
  align-items: baseline !important;
}
.light_version .most-served-tickets-body {
  background: #fff;
  color: #000;
}
.theme-green .text-number-color {
  color: #5cb65f;
}
.theme-cyan .text-number-color {
  color: #17c2d7;
}
.theme-red .text-number-color {
  color: #e15858;
}
.theme-orange .text-number-color {
  color: #ffa117;
}
.theme-blush .text-number-color {
  color: #e25985;
}
.theme-indigo .text-number-color {
  color: #9367b4;
}
.theme-green .text-theme-based-color {
  color: #5cb65f;
}
.theme-cyan .text-theme-based-color {
  color: #17c2d7;
}
.theme-red .text-theme-based-color {
  color: #e15858;
}
.theme-orange .text-theme-based-color {
  color: #ffa117;
}
.theme-blush .text-theme-based-color {
  color: #e25985;
}
.theme-indigo .text-theme-based-color {
  color: #9367b4;
}
.progress-bar-branch-waitings {
  box-shadow: none;
  background-color: rgba(255, 255, 255, 0.03);
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
}
.dashboard-local-waiting-card-body {
  border-radius: 3px;
  font-weight: 400;
  color: #a5a8ad;
  background: #282b2f;
  padding: 14.7px;
  border-top: 1px solid rgba(255, 255, 255, 0.09);
}
.light_version .dashboard-local-waiting-card-body {
  border-radius: 3px;
  font-weight: 400;
  color: #282b2f;
  background: #fff;
  padding: 14.7px;
}

.dashboard-local-waiting-card-body-region {
  border-radius: 3px;
  font-weight: 400;
  color: #a5a8ad;
  background: #282b2f;
  padding: 13.5px;
  border-top: 1px solid rgba(255, 255, 255, 0.09);
}

.light_version .dashboard-local-waiting-card-body-region {
  border-radius: 3px;
  font-weight: 400;
  color: #282b2f;
  background: #fff;
  padding: 13.5px;
}

.most_used_categories {
  padding: 0px 0 12px;
}

.scroll-table-card {
  height: "270px";
  width: "100%";
  overflow-y: "scroll";
}
.modal-list-scroll {
  max-height: 18rem;
  overflow-y: scroll;
}

.swtch-margin {
  margin-left: 100px !important;
}

.progress-bar-danger-animation {
  animation: blink 1s linear;
  animation-iteration-count: infinite;
}

.progress-bar-warning-animation {
  animation: blink 2.5s linear;
  animation-iteration-count: infinite;
}

.cloning-animation {
  animation: blink 2s linear;
  animation-iteration-count: infinite;
}

.remain-danger {
  color: #dc3545;
  /* animation: blink 1.5s linear;
    animation-iteration-count: infinite; */
}
.no-skill-warning {
  animation: blink 1.5s linear;
  animation-iteration-count: infinite;
}

.btn-round-wrench {
  border-radius: 50px;
}

.btn-round-wrench-warning {
  animation: blink 1.5s linear;
  animation-iteration-count: infinite;
}

.fix-color {
  color: #b4b4b4;
  padding: 6px 12px !important;
  border-radius: 50px;
}

.light_version .fix-color {
  color: #383737;
  padding: 6px 12px !important;
  border-radius: 50px;
}
.ticket-design-add-form .input-field-side-text {
  width: 120px;
}
.empty-ticket-design-img {
  object-fit: cover;
  border-radius: 0;
  border: none;
}
.footerImage-avatar,
.headerImage-avatar {
  border: 0;
}
.ticket-img-label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}
.ticket-img-label label {
  margin: 0;
}
.ticket-img-label i {
  height: fit-content;
  margin-right: 15px;
}
.modal-body-section-title {
  border-bottom: 0.5px solid #a5a8ad29;
  padding-bottom: 7px;
}
.modal-body-section-title p {
  margin: 0;
}
.lg-modal-body-scroll {
  max-height: 27rem;
  overflow-y: scroll;
}
.sms-template-badge {
  margin: 0 0 0 8px !important;
}
.menu-dropdown-menu {
  width: unset !important;
  background-color: #282b2f;
}
.light_version .dropdown-menu {
  background-color: #fff;
}
.dropdown-item:active,
.dropdown-item:focus {
  color: #fff;
  text-decoration: none;
  background-color: unset;
}
.dropdown-item:hover,
.dropdown-item:hover i {
  color: #96989c !important;
  text-decoration: none;
  background-color: unset;
}
.accordion .btn.btn-sm.btn-default {
  margin: 0 2px;
}
.megamenu .megamenu_toggle {
  z-index: 9999;
}
.table-co {
  color: white !important;
}
.light_version .table-co {
  color: rgb(42, 41, 41) !important;
}

.analytic-cat-body {
  margin-bottom: 20px;
}
.analytic-cat-body .nav {
  width: 100%;
  overflow-x: scroll;
  padding-bottom: 10px;
  flex-wrap: nowrap !important;
}
.analytic-cat-body .nav .nav-link {
  width: max-content;
}

.date-range {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.most-used-cat .most-used-cat-name {
  max-width: 140px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.catgroup-sub-cat {
  width: 107px;
  margin-left: 15px;
  display: flex;
  justify-content: end;
  align-items: center;
}
.catgroup-sub-cat .group-span {
  line-height: 14px;
}

.carousel .thumbs-wrapper.axis-vertical {
  display: none !important; /* Hide the vertical thumbnails */
}

.carousel .carousel-status {
  display: none !important; /* Hide the vertical thumbnails */
}

.counter-status-margin {
  margin-bottom: 0px !important;
}

.carousel .control-dots .dot {
  display: none !important;
}

.carousel.carousel-slider .control-arrow {
  background-color: #282b2f;
}

.active-xx {
  border: 2px solid #d222e6;
}

/* media queries */
@media (min-width: 1200px) {
  .counter-status-mega .container {
    max-width: 1311px;
  }
}

.group-skill-active {
  background-color: #464646;
}

.clone-modal {
  max-width: 890px !important;
}

.dropdown-text-color {
  color: #77797c !important;
}

.user-activity-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;

  i {
    font-size: 45px;
    margin-bottom: 20px;
  }

  h6 {
    margin-bottom: 6px;
  }
  h4 {
    margin-top: 10px;
  }
}

.suffix {
  position: relative;
  display: flex;
  justify-content: center;
  box-shadow: none;
  background-color: transparent;
  border: 1px solid #393d42;
  border-right: none;
  font-size: 14px;
  color: #77797c;
  align-items: center;
  padding: 0px 15px;
}

.ticket-booking-button.disabled,
.ticket-booking-button:disabled {
  background-color: #266ab3;
  border: 1px solid #266ab3;
}

.appointment-userlist-table-container {
  .MuiPaper-root.MuiPaper-elevation {
    background-color: transparent !important;
  }

  .css-11mde6h-MuiPaper-root {
    box-shadow: none !important;
  }

  .tss-1akey0g-MUIDataTableHeadCell-data,
  .MuiTableCell-root.MuiTableCell-head {
    font-size: 14px !important;
    font-weight: 100;
    text-transform: capitalize;
    margin: 0;
    padding: 5px 0;
  }

  .MuiTableCell-root.MuiTableCell-head {
    /* background-color: #858383 !important; */
    /* backdrop-filter: opacity(60%); */
  }
}
