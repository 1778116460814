body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "barlow";
  font-style: normal;
  font-weight: normal;
  src: url("./assets/fonts/Barlow-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "blinker";
  font-style: normal;
  font-weight: normal;
  src: url("./assets/fonts/Blinker-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "cabin";
  font-style: normal;
  font-weight: normal;
  src: url("./assets/fonts/Cabin-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "darkerGrotesque";
  font-style: normal;
  font-weight: normal;
  src: url("./assets/fonts/DarkerGrotesque-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "jetBrainsMono";
  font-style: normal;
  font-weight: normal;
  src: url("./assets/fonts/JetBrainsMono-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "josefinSans";
  font-style: normal;
  font-weight: normal;
  src: url("./assets/fonts/JosefinSans-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "montserrat";
  font-style: normal;
  font-weight: normal;
  src: url("./assets/fonts/Montserrat-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "quicksand";
  font-style: normal;
  font-weight: normal;
  src: url("./assets/fonts/Quicksand-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "sono";
  font-style: normal;
  font-weight: normal;
  src: url("./assets/fonts/Sono-Regular.ttf") format("truetype");
}